import Vue from 'vue';
import { getExtensionFromString, sbImageSize } from '~/utils/helpers';

// Storyblok Image Service https://www.storyblok.com/docs/image-service
Vue.filter('sbImageService', (filename, options = null, unignoreExt = false) => {
  if (!filename) return filename;

  const path = filename.replace('//s3.amazonaws.com/a.storyblok.com/', '//a.storyblok.com/');
  const width = sbImageSize(path, 'width');
  const result = `${path}/m/${options ? options : ''}`;
  const extension = getExtensionFromString(path);
  const extIgnoreList = ['svg', 'gif'];
  const isIgnoredExt = !unignoreExt && extIgnoreList.includes(extension);

  return isIgnoredExt ? path : result;
});

// Get image size (width or height) from Storyblok's asset url
Vue.filter('sbImageSize', (src, side, fallback) => {
  return sbImageSize(src, side, fallback);
});
