//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import IconArr from '~/assets/img/icons/arr-back.svg?inline';

export default {
  name: 'UiBack',
  components: {
    IconArr,
  },
  computed: {
    ...mapState(['backHomeSlug']),
    linkHome() {
      return {
        linktype: 'story',
        cached_url: this.localePath('/home'),
      }
    },
  },
};
