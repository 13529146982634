import { useStoryblokApi } from "@storyblok/nuxt-2";

export const state = () => ({
  story: null,
});

export const mutations = {
  setLayoutStory(state, story) {
    state.story = story;
  },
};

export const actions = {
  async getLayoutStory({ rootState, commit }) {
    const path = '/layout-config';
    const { version, language } = rootState;

    try {
      const storyblokApi = useStoryblokApi();
      const { data } = await storyblokApi.get(`cdn/stories${path}`, {
        version,
        language,
      });
      const { story } = data;

      commit('setLayoutStory', story);

      return story;
    } catch(err) {
      throw err;
    }
  },
};
