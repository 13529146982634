var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.disabled)?_c('div',{staticClass:"base-link-wr"},[_vm._t("default")],2):(_vm.getLinktype === 'story' && _vm.getPath && !_vm.getTarget)?_c('nuxt-link',{staticClass:"base-link-wr",attrs:{"to":{
    path: _vm.getPath,
    hash: _vm.getHash,
  },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isActive = ref.isActive;
  var isExactActive = ref.isExactActive;
return [_c('a',{class:{
      'nuxt-link-active': isActive,
      'nuxt-link-exact-active': isExactActive,
    },attrs:{"href":href},on:{"click":function($event){return _vm.handleClickNuxt($event, navigate)}}},[_vm._t("default")],2)]}}],null,true)}):_c('a',{staticClass:"base-link-wr",attrs:{"href":_vm.getPath,"rel":_vm.getRel,"target":_vm.getTarget,"title":_vm.getTitle},on:{"click":_vm.handleClick}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }