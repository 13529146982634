export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"uk","silentTranslationWarn":true,"messages":{"uk":{"to-homepage":"На головну","back":"Назад","infinite-error":"Упс, щось пішло не так :(","infinite-retry":"Спробуйте ще раз","prev-slide":"Попередній слайд","next-slide":"Наступний слайд","open-filters":"Відкрити фільтри","close-filters":"Закрити фільтри","back-filters":"Назад"},"en":{"to-homepage":"To homepage","back":"Back","infinite-error":"Opps, something went wrong :(","infinite-retry":"Retry","prev-slide":"Previous slide","next-slide":"Next slide","open-filters":"Open filters","close-filters":"Close filters","back-filters":"Back"}}},
  vueI18nLoader: true,
  locales: [{"code":"uk","iso":"uk","name":"UA","title":"Українською"},{"code":"en","iso":"en","name":"EN","title":"In English"}],
  defaultLocale: "uk",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: null,
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://spiilka.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"uk","iso":"uk","name":"UA","title":"Українською"},{"code":"en","iso":"en","name":"EN","title":"In English"}],
  localeCodes: ["uk","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "}",
}

export const localeMessages = {}
