//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getStoryLink, removeEndTrailingSlash } from '~/utils/helpers';

export default {
  name: 'LayoutFooter',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    topLinks() {
      const cur = removeEndTrailingSlash(this.$route.path);

      return this.blok.top_links?.filter(i => removeEndTrailingSlash(getStoryLink(i.link)) !== cur) || [];
    },
    topLinksDesk() {
      return this.topLinks?.filter(i => !i.desktop_hide) || [];
    },
    copyright() {
      const text = this.blok.copyright;
      const regex = /\$\{year\}/gm;
      const str = text ? text : '';
      const subst = new Date().getFullYear();

      return str.replace(regex, subst);
    },
    linkHome() {
      return {
        linktype: 'story',
        cached_url: this.localePath('/home'),
      }
    },
  },
};
