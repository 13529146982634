import Vue from 'vue';
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer';
import { Block, Mark } from '@marvr/storyblok-rich-text-types';
import Image from '~/components/richtext/blocks/Image.vue';
import Link from '~/components/richtext/marks/Link.vue';

// https://storyblok-rich-text-renderer.netlify.app/vue-plugin/config/#resolvers
Vue.use(VueRichTextRenderer, {
  resolvers: {
    blocks: {
      [Block.IMAGE]: Image,
    },
    marks: {
      [Mark.LINK]: Link,
    },
    components: {
    },
  }
});
