import { useStoryblokApi } from "@storyblok/nuxt-2";

export const state = () => ({
  projects: null,
  categories: null,
  allCategories: null,
});

export const mutations = {
  setProjects(state, data) {
    state.projects = data;
  },
  setCategories(state, data) {
    state.categories = data;
  },
  setAllCategories(state, data) {
    state.allCategories = data;
  },
};

export const actions = {
  async getProjects({ rootState, commit }, { filter_query, page, per_page }) {
    const starts_with = 'projects/';
    const { language } = rootState;
    const version = 'published';

    try {
      const resolve_relations = ['state'];
      const storyblokApi = useStoryblokApi();
      const result = await storyblokApi.get('cdn/stories', {
        version,
        language,
        starts_with,
        filter_query,
        page: page || 1,
        per_page: per_page || 25,
        is_startpage: false,
        sort_by: 'content.date:desc',
        content_type: 'page-project',
        excluding_fields: 'body,richtext,categories,meta_image,meta_title,meta_keywords,meta_description',
        resolve_relations: resolve_relations.join(),
      });

      const { stories, rels } = result.data;

      stories.forEach(story => {
        if (resolve_relations?.length && rels?.length) {
          resolve_relations.forEach(r => {
            if (story.content[r]?.length) {
              if (typeof story.content[r] === 'string') {
                // Resolve Single-Option
                story.content[r] = rels.find((i) => i.uuid === story.content[r]);
              } else {
                // Resolve Multi-Options
                story.content[r] = story.content[r].reduce((list, uuid) => {
                  const rel = rels.find((item) => item.uuid === uuid);
                  if (!rel) return list;

                  list.push(rel);
                  return list;
                }, []);
              }
            }
          });
        }
      });

      commit('setProjects', result);

      return result;
    } catch(err) {
      throw err;
    }
  },
  async getCategories({ rootState, commit }) {
    const starts_with = 'categories/';
    const { language } = rootState;
    const version = 'published';
    const storyblokApi = useStoryblokApi();

    const fetch = async (payload = {}) => {
      const result = await storyblokApi.get('cdn/stories', {
        version,
        language,
        starts_with,
        sort_by: 'position:asc',
        ...payload
      });

      return result;
    };
    const fetchAll = async (payload = {}) => {
      const result = await fetch({
        page: 1,
        per_page: 100,
        ...payload
      });
      const { total, perPage } = result;

      if (total > perPage) {
        const maxPages = Math.ceil(total / perPage);

        for (let page = 2; page <= maxPages; page++) {
          const { data } = await fetch({
            page,
            per_page: perPage,
            ...payload
          });

          result.data.stories = [
            ...result.data.stories,
            ...data.stories
          ];
        }
      }

      return result;
    };

    try {
      const { data: { stories } } = await fetchAll({
        level: 2,
        is_startpage: false,
        content_type: 'option-category',
      });
      const { data: { stories: foldersRoot } } = await fetchAll({
        level: 2,
        is_startpage: true,
        content_type: 'option-category-root',
      });
      const { data: { stories: foldersItems } } = await fetchAll({
        level: 3,
        is_startpage: false,
        content_type: 'option-category',
      });

      let storiesDropdowns = [];
      const dropdowns = foldersRoot?.length && foldersItems?.length ? foldersRoot.map(root => {
        const dropdown_list = foldersItems.filter(i => i.full_slug.startsWith(root.full_slug));
        storiesDropdowns = [...storiesDropdowns, ...dropdown_list];

        return {
          ...root,
          dropdown_list,
        }
      }) : [];

      const result = [...stories, ...dropdowns];
      const all_result = [...stories, ...storiesDropdowns];

      commit('setCategories', result);
      commit('setAllCategories', all_result);

      return result;
    } catch(err) {
      throw err;
    }
  },
};
