//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fixStoryPath } from '~/utils/helpers';

export default {
  name: "RichtextMarksLink",
  props: {
    attrs: {
      type: Object,
      required: true,
    },
  },
  computed: {
    linktype() {
      return this.attrs.linktype;
    },
    href() {
      const href = this.attrs.href;

      if (this.linktype === 'story') {
        const path = fixStoryPath(href);
        const hash = this.anchor ? `#${this.anchor}` : '';

        if (this.target === '_blank') {
          return path + hash;
        }

        return { path, hash };
      }

      if (this.linktype === 'email') {
        return `mailto:${href}`;
      }

      return href;
    },
    target() {
      return this.attrs.target || null;
    },
    anchor() {
      return this.attrs.anchor;
    },
    custom() {
      return this.attrs.custom;
    },
  },
};
