export const state = () => ({
  version: null,
  language: null,
  backHomeSlug: '',
});

export const mutations = {
  setVersionAndLang(state, data) {
    state.version = data.version;
    state.language = data.language;
  },
  setBackHomeSlug(state, slug) {
    state.backHomeSlug = slug;
  },
};

export const actions = {
  nuxtServerInit({ dispatch, commit }, { query, isDev }) {
    dispatch('getVersionAndLang', { query, isDev });
  },
  getVersionAndLang({ commit }, { query, isDev }) {
    const { _storyblok } = query;
    const { locale, defaultLocale } = this.$i18n;
    const isPreview = !!(_storyblok && _storyblok !== '') || isDev;
    const version = isPreview ? 'draft' : 'published';
    const language = locale === defaultLocale ? 'default' : locale;

    commit('setVersionAndLang', { version, language });

    return { version, language };
  },
};
