//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'NuxtError',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState('layout', {
      globalSitename: state => state.story?.content?.meta_sitename,
    }),
    status() {
      return this.error.status || 500;
    },
    statusText() {
      return this.error.response || 'Something went wrong';
    },
    title() {
      return `${this.status} - ${this.statusText}`;
    },
    metaTitle() {
      return `${this.title} | ${this.globalSitename}`;
    },
    linkHome() {
      return {
        linktype: 'story',
        cached_url: this.localePath('/home'),
      }
    },
  },
  head() {
    let head = {
      meta: [],
      link: [],
    };

    if (this.metaTitle) {
      head.title = this.metaTitle;
      head.meta.push(
        { hid: 'og:title', property: 'og:title', content: this.metaTitle },
        { hid: 'twitter:title', property: 'twitter:title', content: this.metaTitle },
      );
    }

    return head;
  }
}
