//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  name: 'LayoutsDefault',
  computed: {
    ...mapState('layout', ['story']),
    content() {
      return this.story?.content;
    },
    header() {
      return this.content?.header || [];
    },
    footer() {
      return this.content?.footer || [];
    },
    info() {
      const footer = this.footer?.[0];

      return {
        address: footer?.address,
        social_links: footer?.social_links,
      }
    },
  },
  async fetch() {
    try {
      await this.getLayoutStory();
    } catch(err) {
      console.error({ err });
    }
  },
  methods: {
    ...mapActions('layout', ['getLayoutStory']),
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      bodyAttrs: {
        'data-aos-easing': 'ease-out',
        'data-aos-duration': 400,
        'data-aos-delay': 0,
      },
      meta: [
        ...i18nHead.meta,
      ],
      link: [
        ...i18nHead.link,
      ],
    };
  },
};
